import React, {useEffect, useReducer, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {i18n} from "../../translate/i18n";
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../services/api";
import {toast} from "react-toastify";
import openSocket from "socket.io-client";
import SendProductModal from "../SendProductModal";
import ConfirmationModal from "../ConfirmationModal";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import TableRowSkeleton from "../TableRowSkeleton";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import jwt_decode from "jwt-decode";

const reducer = (state, action) => {
    if (action.type === "LOAD_PRODUCTS") {
        const products = action.payload;
        const newProducts = [];

        products.forEach(product => {
            const productIndex = state.findIndex(c => c.id === product.id);
            if (productIndex !== -1) {
                state[productIndex] = product;
            } else {
                newProducts.push(product);
            }
        });

        return [...state, ...newProducts];
    }

    if (action.type === "UPDATE_PRODUCTS") {
        const product = action.payload;
        const productIndex = state.findIndex(c => c.id === product.id);

        if (productIndex !== -1) {
            state[productIndex] = product;
            return [...state];
        } else {
            return [product, ...state];
        }
    }

    if (action.type === "DELETE_PRODUCT") {
        const productId = action.payload;

        const productIndex = state.findIndex(c => c.id === productId);
        if (productIndex !== -1) {
            state.splice(productIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#eee",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: "#eee",
        flexDirection: "column",
        padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    tabsHeader: {
        flex: "none",
        height: "100%",
        backgroundColor: "#e7e7e7",
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    ticketsListActions: {
        flex: "none",
        marginLeft: "auto",
    },

    searchBox: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        background: "#fafafa",
        padding: "10px 13px",
    },

    serachInputWrapper: {
        flex: 1,
        background: "#fff",
        display: "flex",
        borderRadius: 40,
        padding: 4,
    },

    searchIcon: {
        color: "grey",
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30,
    },
    card: {
        maxWidth: "100%",
        float: "left",
    },
    mainPaper :{
        marginTop: "10px"
    },

    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));

const ProductsManager = ({setProductOpen}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [products, dispatch] = useReducer(reducer, []);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [deletingProduct, setDeletingProduct] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({type: "RESET"});
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchProducts = async () => {
                try {
                    const {data} = await api.get("/products/", {
                        params: {searchParam, pageNumber},
                    });
                    dispatch({type: "LOAD_PRODUCTS", payload: data.products});
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    const errorMsg = err.response?.data?.error;
                    if (errorMsg) {
                        if (i18n.exists(`backendErrors.${errorMsg}`)) {
                            toast.error(i18n.t(`backendErrors.${errorMsg}`));
                        } else {
                            toast.error(err.response.data.error);
                        }
                    } else {
                        toast.error("Unknown error");
                    }
                }
            };
            fetchProducts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        var token = localStorage.getItem("token");
        var decoded = jwt_decode(token);
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        socket.on("product" + decoded.platform, data => {
            if (data.action === "update" || data.action === "create") {
                dispatch({type: "UPDATE_PRODUCTS", payload: data.product});
            }

            if (data.action === "delete") {
                dispatch({type: "DELETE_PRODUCT", payload: +data.productId});
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleCloseProductModal = () => {
        setSelectedProductId(null);
        setProductModalOpen(false);
    };

    const hadleEditProduct = productId => {
        setLoading(true);
        setSelectedProductId(productId);
        setProductModalOpen(true);
        setLoading(false);
    };

    const handleDeleteProduct = async productId => {
        setLoading(true);
        try {
            await api.delete(`/products/${productId}`);
            setLoading(false);
            toast.success(i18n.t("products.toasts.deleted"));
        } catch (err) {
            const errorMsg = err.response?.data?.error;
            setLoading(false);
            if (errorMsg) {
                if (i18n.exists(`backendErrors.${errorMsg}`)) {
                    toast.error(i18n.t(`backendErrors.${errorMsg}`));
                } else {
                    toast.error(err.response.data.error);
                }
            } else {
                toast.error("Unknown error");
            }
        }
        setDeletingProduct(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleimportProduct = async () => {
        setLoading(true);
        try {
            await api.post("/products/import");
            setLoading(false);
            window.location.reload(false);
        } catch (err) {
            const errorMsg = err.response?.data?.error;
            setLoading(false);
            if (errorMsg) {
                if (i18n.exists(`backendErrors.${errorMsg}`)) {
                    toast.error(i18n.t(`backendErrors.${errorMsg}`));
                } else {
                    toast.error(err.response.data.error);
                }
            } else {
                toast.error("Unknown error");
            }
        }
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <Paper className={classes.mainContainer}>

            <ConfirmationModal
                title={
                    deletingProduct
                        ? `${i18n.t("products.confirmationModal.deleteTitle")} ${
                            deletingProduct.name
                        }?`
                        : `${i18n.t("products.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={e =>
                    deletingProduct
                        ? handleDeleteProduct(deletingProduct.id)
                        : handleimportProduct()
                }
            >
                {deletingProduct
                    ? `${i18n.t("products.confirmationModal.deleteMessage")}`
                    : `${i18n.t("products.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            <div className={classes.header}>
                <IconButton  onClick={() => setProductOpen(false)}>
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: "center" }}>
                    {i18n.t("products.title")}
                </Typography>
            </div>
            {loading ? (
                <ContactDrawerSkeleton classes={classes}/>
            ) : (
                <div className={classes.content}>
                    <TextField
                        placeholder={i18n.t("products.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{color: "gray"}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        {productModalOpen && !loading ? (
                            <SendProductModal
                                open={productModalOpen}
                                onClose={handleCloseProductModal}
                                aria-labelledby="form-dialog-title"
                                productId={selectedProductId}
                            ></SendProductModal>

                        ) : (
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"/>
                                        <TableCell>{i18n.t("products.table.name")}</TableCell>
                                        <TableCell>{i18n.t("products.table.explanation")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {products.map(product => (
                                            <TableRow key={product.id} onClick={() => hadleEditProduct(product.id)}>
                                                <TableCell style={{paddingRight: 0}}>
                                                    {<Avatar src={product.imageUrl}/>}
                                                </TableCell>
                                                <TableCell>{product.name}</TableCell>
                                                <TableCell>{product.explanation}</TableCell>
                                            </TableRow>
                                        ))}
                                        {loading && <TableRowSkeleton/>}
                                    </>
                                </TableBody>
                            </Table>
                        )}

                    </Paper>
                </div>)
            }
        </Paper>
    );
};

export default ProductsManager;
