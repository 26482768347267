import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import TableRowSkeleton from "../TableRowSkeleton";

import {useParams} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    card:{
        width: "100%"
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: "25ch",
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        // margin: theme.spacing(1),
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

// const ProductSchema = Yup.object().shape({
//     name: Yup.string()
//         .min(2, "Too Short!")
//         .max(50, "Too Long!")
//         .required("Required"),
//     number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
//     email: Yup.string().email("Invalid email"),
// });

const SendProductModal = ({ open, onClose, productId }) => {
    const classes = useStyles();
    const { ticketId } = useParams();

    const initialState = {
        name: "",
        type: "",
        color: "",
        size: "",
        explanation: "",
        platform: "",
        imageUrl: ""
    };

    const [product, setProduct] = useState(initialState);

    useEffect(() => {
        const fetchProduct = async () => {
            if (!productId) return;
            try {
                const { data } = await api.get(`/products/${productId}`);
                setProduct(data);
            } catch (err) {
                const errorMsg = err.response?.data?.error;
                if (errorMsg) {
                    if (i18n.exists(`backendErrors.${errorMsg}`)) {
                        toast.error(i18n.t(`backendErrors.${errorMsg}`));
                    } else {
                        toast.error(err.response.data.error);
                    }
                } else {
                    toast.error("Unknown error");
                }
            }
        };

        fetchProduct();
    }, [productId, open]);

    const handleClose = () => {
        onClose();
        setProduct(initialState);
    };


    const handleUploadMedia = async product => {

        const message = {
            read: 1,
            productId: product.id,
            name:product.name,
            explanation:product.explanation,
            fields: product.productCustomFields,
            fromMe: true,
            mediaUrl: "",
            body: ``,
            fromUser: ``
        };
        try {
            await api.post(`/productMessage/${ticketId}`, message);
        } catch (err) {
            const errorMsg = err.response?.data?.error;
            if (errorMsg === "ERR_SENDING_WAPP_MSG") {
                toast.error(i18n.t("messagesInput.toasts.error"));
            } else {
                toast.error(err.response.data.error);
            }
        }

    };

    // const handleSendMessage = async () => {
    //     const message = {
    //         read: 1,
    //         fromMe: true,
    //         mediaUrl: "",
    //         body: ``,
    //         fromUser: ``
    //     };
    //     try {
    //         await api.post(`/messages/${ticketId}`, message);
    //     } catch (err) {
    //         const errorMsg = err.response?.data?.error;
    //         if (errorMsg) {
    //             if (i18n.exists(`backendErrors.${errorMsg}`)) {
    //                 toast.error(i18n.t(`backendErrors.${errorMsg}`));
    //             } else {
    //                 toast.error(err.response.data.error);
    //             }
    //         } else {
    //             toast.error("Unknown error");
    //         }
    //     }
    // };


    return (
        <div className={classes.root}>
            <>
                <IconButton onClick={() => handleClose(false)}>
                    <CloseIcon />
                </IconButton>
                <Card className={classes.card}>
                    <CardActionArea>
                        {product.imageUrl !== "" && product.imageUrl ? (<CardMedia
                            component="img"
                            image={product.imageUrl}
                            src={product.imageUrl}
                            width="100%"
                            title={product.name}
                        /> )
                        :(
                                <>
                            </>
                        )}

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {product.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {product.explanation}
                            </Typography>
                            {product.productCustomFields &&
                            product.productCustomFields.length > 0 ? (
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{i18n.t("products.table.name")}</TableCell>
                                                <TableCell>{i18n.t("products.table.value")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <>
                                                {product.productCustomFields &&
                                                product.productCustomFields.length > 0 &&
                                                product.productCustomFields.map((prod, index) => (
                                                    <TableRow key={prod.id}>
                                                        <TableCell>{prod.name}</TableCell>
                                                        <TableCell>{prod.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        </TableBody>
                                    </Table>
                            ) : (
                                <TableRowSkeleton/>
                            )}

                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="fullWidth" onClick={() => handleUploadMedia(product)} className={classes.button}>
                            Gönder
                        </Button>
                    </CardActions>
                </Card>


            </>
        </div>
    );
};

export default SendProductModal;
