import React, { useState, useEffect, useReducer } from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ProductModal from "../../components/ProductModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import jwt_decode from "jwt-decode";

const reducer = (state, action) => {
    if (action.type === "LOAD_PRODUCTS") {
        const products = action.payload;
        const newProducts = [];

        products.forEach(product => {
            const productIndex = state.findIndex(c => c.id === product.id);
            if (productIndex !== -1) {
                state[productIndex] = product;
            } else {
                newProducts.push(product);
            }
        });

        return [...state, ...newProducts];
    }

    if (action.type === "UPDATE_PRODUCTS") {
        const product = action.payload;
        const productIndex = state.findIndex(c => c.id === product.id);

        if (productIndex !== -1) {
            state[productIndex] = product;
            return [...state];
        } else {
            return [product, ...state];
        }
    }

    if (action.type === "DELETE_PRODUCT") {
        const productId = action.payload;

        const productIndex = state.findIndex(c => c.id === productId);
        if (productIndex !== -1) {
            state.splice(productIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Products = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [products, dispatch] = useReducer(reducer, []);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [deletingProduct, setDeletingProduct] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchProducts = async () => {
                try {
                    const { data } = await api.get("/products/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_PRODUCTS", payload: data.products });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    const errorMsg = err.response?.data?.error;
                    if (errorMsg) {
                        if (i18n.exists(`backendErrors.${errorMsg}`)) {
                            toast.error(i18n.t(`backendErrors.${errorMsg}`));
                        } else {
                            toast.error(err.response.data.error);
                        }
                    } else {
                        toast.error("Unknown error");
                    }
                }
            };
            fetchProducts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        var token = localStorage.getItem("token");
        var decoded = jwt_decode(token);
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        socket.on("product" + decoded.platform, data => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_PRODUCTS", payload: data.product });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_PRODUCT", payload: +data.productId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenProductModal = () => {
        setSelectedProductId(null);
        setProductModalOpen(true);
    };

    const handleCloseProductModal = () => {
        setSelectedProductId(null);
        setProductModalOpen(false);
    };

    const hadleEditProduct = productId => {
        setSelectedProductId(productId);
        setProductModalOpen(true);
    };

    const handleDeleteProduct = async productId => {
        try {
            await api.delete(`/products/${productId}`);
            toast.success(i18n.t("products.toasts.deleted"));
        } catch (err) {
            const errorMsg = err.response?.data?.error;
            if (errorMsg) {
                if (i18n.exists(`backendErrors.${errorMsg}`)) {
                    toast.error(i18n.t(`backendErrors.${errorMsg}`));
                } else {
                    toast.error(err.response.data.error);
                }
            } else {
                toast.error("Unknown error");
            }
        }
        setDeletingProduct(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleimportProduct = async () => {
        try {
            await api.post("/products/import");
            window.location.reload(false);
        } catch (err) {
            const errorMsg = err.response?.data?.error;
            if (errorMsg) {
                if (i18n.exists(`backendErrors.${errorMsg}`)) {
                    toast.error(i18n.t(`backendErrors.${errorMsg}`));
                } else {
                    toast.error(err.response.data.error);
                }
            } else {
                toast.error("Unknown error");
            }
        }
    };

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <ProductModal
                open={productModalOpen}
                onClose={handleCloseProductModal}
                aria-labelledby="form-dialog-title"
                productId={selectedProductId}
            ></ProductModal>
            <ConfirmationModal
                title={
                    deletingProduct
                        ? `${i18n.t("products.confirmationModal.deleteTitle")} ${
                            deletingProduct.name
                        }?`
                        : `${i18n.t("products.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={e =>
                    deletingProduct
                        ? handleDeleteProduct(deletingProduct.id)
                        : handleimportProduct()
                }
            >
                {deletingProduct
                    ? `${i18n.t("products.confirmationModal.deleteMessage")}`
                    : `${i18n.t("products.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            <MainHeader>
                <Title>{i18n.t("products.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("products.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenProductModal}
                    >
                        {i18n.t("products.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>{i18n.t("products.table.name")}</TableCell>
                            <TableCell>{i18n.t("products.table.explanation")}</TableCell>
                            <TableCell align="right">
                                {i18n.t("products.table.actions")}
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {products.map(product => (
                                <TableRow key={product.id}>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={product.imageUrl} />}
                                    </TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.explanation}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            size="small"
                                            onClick={() => hadleEditProduct(product.id)}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmOpen(true);
                                                setDeletingProduct(product);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Products;
