import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";
import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import jwt_decode from "jwt-decode";

const QrcodeModal = ({ open, onClose, whatsAppId, qrCode1 }) => {
	const [qrCode, setQrCode] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.session.qrcode); // setQrCode burada çağırılıyor
			} catch (err) {
				const errorMsg = err.response?.data?.error;
				if (errorMsg) {
					if (i18n.exists(`backendErrors.${errorMsg}`)) {
						toast.error(i18n.t(`backendErrors.${errorMsg}`));
					} else {
						toast.error(err.response.data.error);
					}
				} else {
					toast.error("Unknown error");
				}
			}
		};
		fetchSession();
	}, [whatsAppId, setQrCode]);

	useEffect(() => {
		if (!whatsAppId) return;
		var token = localStorage.getItem("token");
		var decoded = jwt_decode(token);
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL, { query: { token } });

		setQrCode(qrCode1); // setQrCode burada çağırılıyor

		console.log(process.env.REACT_APP_BACKEND_URL);
		socket.on("whatsappSession" + decoded.platform, data => {
			if (decoded.platform === data.platform) {
				console.log(data);
				if (data.action === "update" && data.session.id === whatsAppId) {
					setQrCode(data.session.qrcode); // setQrCode burada çağırılıyor
				}

				if (data.action === "update" && data.session.qrcode === "") {
					onClose();
				}
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [whatsAppId, onClose, setQrCode, qrCode1]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0}>
					<Typography color="primary" gutterBottom>
						{i18n.t("qrCode.message")}
					</Typography>

					{qrCode ? (
						<QRCode value={qrCode} size={256}/>
					) : qrCode1 ? (
						<QRCode value={qrCode1} size={256}/>
					) : (
						<span>
							{qrCode} {qrCode1} Waiting for Screen Shot</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
