import React from "react";

import MainContainer from "../../components/MainContainer";
import Grid from "@material-ui/core/Grid";
//import { makeStyles } from "@material-ui/core/styles";


// const useStyles = makeStyles(theme => ({
//     container: {
//         paddingTop: theme.spacing(4),
//         paddingBottom: theme.spacing(4),
//     },
//     fixedHeightPaper: {
//         padding: theme.spacing(2),
//         display: "flex",
//         overflow: "auto",
//         flexDirection: "column",
//         height: 240,
//     },
// }));

const Bots = () => {
    return (
        <div>
            <MainContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        TEST
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    );
};

export default Bots;
