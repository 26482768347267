import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		// marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		// width: "25ch",
		flex: 1,
	},
	textArea: {
		// marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "100%",
		minHeight: "60px",
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		// margin: theme.spacing(1),
		position: "relative",
	},

	uploadInput: {
		display: "none",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ProductSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const ProductModal = ({ open, onClose, productId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		type: "",
		color: "",
		size: "",
		platform: "",
		explanation: "",
		imageUrl: ""
	};

	const [product, setProduct] = useState(initialState);
	const mediaInitialState = { preview: "", raw: "", name: "" };
	const [media, setMedia] = useState(mediaInitialState);
	const [loading, setLoading] = useState(false);
	// const [recording, setRecording] = useState(false);

	useEffect(() => {
		const fetchProduct = async () => {
			if (!productId) return;
			try {
				const { data } = await api.get(`/products/${productId}`);
				setProduct(data);
			} catch (err) {
				const errorMsg = err.response?.data?.error;
				if (errorMsg) {
					if (i18n.exists(`backendErrors.${errorMsg}`)) {
						toast.error(i18n.t(`backendErrors.${errorMsg}`));
					} else {
						toast.error(err.response.data.error);
					}
				} else {
					toast.error("Unknown error");
				}
			}
		};

		fetchProduct();
	}, [productId, open]);

	const handleChangeMedia = e => {
		if (e.target.files.length) {
			setMedia({
				preview: URL.createObjectURL(e.target.files[0]),
				raw: e.target.files[0],
				name: e.target.files[0].name,
			});
		}
	};

	const handleClose = () => {
		onClose();
		setProduct(initialState);
	};

	const handleSaveProduct = async values => {
		setLoading(true);
		const formData = new FormData();
		let data = JSON.stringify(values);
		formData.append("media", media.raw);
		formData.append("data", data);

		values.formData = formData;
		try {
			if (productId) {
				await api.put(`/products/${productId}`, formData);
			} else {
				await api.post("/products", formData);
			}
			toast.success(i18n.t("productModal.success"));
		} catch (err) {
			const errorMsg = err.response?.data?.error;
			if (errorMsg) {
				if (i18n.exists(`backendErrors.${errorMsg}`)) {
					toast.error(i18n.t(`backendErrors.${errorMsg}`));
				} else {
					toast.error(err.response.data.error);
				}
			} else {
				toast.error("Unknown error");
			}
		}
		setLoading(false);
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{productId
						? `${i18n.t("productModal.title.edit")}`
						: `${i18n.t("productModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={product}
					enableReinitialize={true}
					validationSchema={ProductSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveProduct(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("productModal.form.mainInfo")}
								</Typography>
								<Field
									as={TextField}
									label={i18n.t("productModal.form.name")}
									name="name"
									autoFocus
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>
								<Typography variant="subtitle1">
									{i18n.t("productModal.form.image")}
								</Typography>
								<input
									type="file"
									id="upload-button"
									disabled={loading}
									className={classes.uploadInput}
									onChange={handleChangeMedia}
								/>
								<label htmlFor="upload-button">
									<IconButton
										aria-label="upload"
										component="span"
										disabled={loading }
									>
										<AttachFileIcon className={classes.sendMessageIcons} />
									</IconButton>
								</label>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("productModal.form.explanation")}
								</Typography>
								<Field
									as={TextField}
									name="explanation"
									autoFocus
									error={touched.explanation && Boolean(errors.explanation)}
									helperText={touched.explanation && errors.explanation}
									variant="outlined"
									multiline
									margin="dense"
									className={classes.textArea}
								/>

								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("productModal.form.productCustomFields")}
								</Typography>

								<FieldArray name="productCustomFields">
									{({ push, remove }) => (
										<>
											{values.productCustomFields &&
												values.productCustomFields.length > 0 &&
												values.productCustomFields.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															as={TextField}
															label={i18n.t("productModal.form.extraName")}
															name={`productCustomFields[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															as={TextField}
															label={i18n.t("productModal.form.extraValue")}
															name={`productCustomFields[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="primary"
													onClick={() => push({ name: "", value: "" })}
												>
													{`+ ${i18n.t("productModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("productModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{productId
										? `${i18n.t("productModal.buttons.okEdit")}`
										: `${i18n.t("productModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ProductModal;
