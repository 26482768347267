import React, { useState, useEffect, useReducer, useCallback } from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Button,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	Table,
	TableHead,
	Paper,
} from "@material-ui/core";
import { Edit, CheckCircle, WhatsApp } from "@material-ui/icons";
import jwt_decode from "jwt-decode";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";

const reducer = (state, action) => {
	if (action.type === "LOAD_WHATSAPPS") {
		const whatsApps = action.payload;
		return [...whatsApps];
	}
	if (action.type === "tokenParams") {
		const tokenParams = action.payload;
		return [...tokenParams];
	}
	if (action.type === "UPDATE_WHATSAPPS") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);
		if (whatsAppIndex !== -1) {
			state[whatsAppIndex] = whatsApp;
			return [...state];
		} else {
			return [whatsApp, ...state];
		}

	}
	if (action.type === "UPDATE_SESSION") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);
		if (whatsAppIndex !== -1) {
			state[whatsAppIndex].status = whatsApp.status;
			state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
			state[whatsAppIndex].qrcode = whatsApp.qrcode;
			return [...state];
		} else {
			return [...state];
		}
	}
	if (action.type === "DELETE_WHATSAPPS") {
		const whatsAppId = action.payload;
		const whatsAppIndex = state.findIndex((s) => s.id === whatsAppId);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1);
		}
		return [...state];
	}
	if (action.type === "RESET") {
		return [];
	}
};

const useStyles = makeStyles((theme) => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
}));

const Connections = () => {
	const classes = useStyles();
	const [whatsApps, dispatch] = useReducer(reducer, []);
	const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [deletingWhatsApp, setDeletingWhatsApp] = useState(null);
	const [qrCode, setQrCode] = useState(""); // setQrCode tanımı burada

	const Paddle = window.Paddle;
	var intervals = {};

	function checkoutClosed(data) {
		console.log(data);
		// alert('Your purchase has been cancelled, we hope to see you again soon!');
	}

	function checkoutComplete(data) {
		console.log(data);
		// alert('Thanks for your purchase.');
	}

	const openCheckout = async (whatsapp, override, substatus) => {
		var tokenParams = await api.get("/getTokenParameters");
		console.log(tokenParams.data);
		let extra = tokenParams.data;
		extra.whatsapp = whatsapp;
		if(substatus === "cancel"){
			const data = {
				status : false
			}
			await api.post(`/whatsapp/update-status/${whatsapp.id}`, data);
		}

		let checkoutOptions = {
			product: 630871,
			email: extra.email , // Burada e-posta adresini doğrudan geçin

			passthrough: extra,
			successCallback: checkoutComplete,
			closeCallback: checkoutClosed,
		};

		if (override) {
			checkoutOptions.override = override;
		}

		Paddle.Checkout.open(checkoutOptions);
	};


	function calculateLastDate(whatsapp) {
		// Set the date we're counting down to
		var countDownDate = new Date(whatsapp.lastDate).getTime();

		// Update the count down every 1 second
		intervals[whatsapp.id] = setInterval(function () {
			// Get today's date and time
			var date = new Date();
			date.setHours(date.getHours() - 6);
			var now = date.getTime();
			// Find the distance between now and the count down date
			var distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			if (document.getElementById(whatsapp.id)) {
				document.getElementById(whatsapp.id).innerHTML =
					days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

				// If the count down is over, write some text
				if (distance < 0) {
					clearInterval(intervals[whatsapp.id]);
					document.getElementById(whatsapp.id).innerHTML = "EXPIRED";
				}
			}
		}, 1000);
	}

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/whatsapp/");
				dispatch({ type: "LOAD_WHATSAPPS", payload: data });
				dispatch({ type: "UPDATE_SESSION", payload: data });
				handleCloseQrModal();
				if (data && data.length > 0) {
					data.forEach((session) => {
						if (session.status === "qrcode") {
							setQrCode(session.qrcode);
						}
					});
				}
			} catch (err) {
				const errorMsg = err.response?.data?.error;
				if (errorMsg) {
					if (i18n.exists(`backendErrors.${errorMsg}`)) {
						toast.error(i18n.t(`backendErrors.${errorMsg}`));
					} else {
						toast.error(err.response.data.error);
					}
				} else {
					toast.error("Unknown error");
				}
			}
		};
		fetchSession();
	}, [setQrCode]);

	useEffect(() => {
		var token = localStorage.getItem("token");
		var decoded = jwt_decode(token);
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL, { query: { token } });
		const platform = decoded.platform;

		socket.emit("join_room", `whatsapp${platform}`, function (err, room) {
			if (err) {
				console.log(err);
			} else {
				console.log(room);
			}
		});
		socket.emit("joinRoom", `whatsapp${platform}`, function (err, room) {
			if (err) {
				console.log(err);
			} else {
				console.log(room);
			}
		});
		socket.emit("joinRoom", `whatsappSession${platform}`, function (err, room) {
			if (err) {
				console.log(err);
			} else {
				console.log(room);
			}
		});


		console.log("Joining rooms:", `whatsappSession${platform}`, `whatsapp${platform}`);

		socket.on("joinRoom", (data) => {
			console.log("Received data on whatsapp" + platform, data);
		});

		socket.on("whatsapp" + platform, (data) => {
			console.log("Received data on whatsapp" + platform, data);
			if (platform === data.platform) {
				if (data.action === "update") {
					dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
				}
			}
		});

		socket.on("whatsapp" + platform, (data) => {
			console.log("Received data on whatsapp" + platform, data);
			if (data.action === "delete") {
				dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
			}
		});

		socket.on("whatsappSession" + platform, (data) => {
			console.log("Received data on whatsappSession" + platform, data);
			if (platform === data.platform) {
				if (data.action === "update") {
					dispatch({ type: "UPDATE_SESSION", payload: data.session });
					handleCloseQrModal();
				}
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleCloseWhatsAppModal = useCallback(() => {
		setWhatsAppModalOpen(false);
		setSelectedWhatsApp(null);
	}, [setSelectedWhatsApp, setWhatsAppModalOpen]);

	const handleOpenQrModal = (whatsApp) => {
		console.log(whatsApp)
		setSelectedWhatsApp(whatsApp);
		setQrCode(whatsApp.qrcode); // setQrCode burada çağırılacak
		setQrModalOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

	const handleEditWhatsApp = (whatsApp) => {
		setSelectedWhatsApp(whatsApp);
		setWhatsAppModalOpen(true);
	};

	const handleDeleteWhatsApp = async (whatsAppId) => {
		try {
			await api.delete(`/whatsapp/${whatsAppId}`);
			toast.success(i18n.t("connections.toasts.deleted"));
		} catch (err) {
			const errorMsg = err.response?.data?.error;
			if (errorMsg) {
				if (i18n.exists(`backendErrors.${errorMsg}`)) {
					toast.error(i18n.t(`backendErrors.${errorMsg}`));
				} else {
					toast.error(err.response.data.error);
				}
			} else {
				toast.error("Unknown error");
			}
		}
		setDeletingWhatsApp(null);
	};

	const handleChangeWhatsapp = async (whatsAppId) => {
		try {
			await api.delete(`/whatsappChange/${whatsAppId}`);
			toast.success(i18n.t("connections.toasts.deleted"));
		} catch (err) {
			console.log(err);
			if (err.response && err.response.data && err.response.data.error) {
				toast.error(err.response.data.error);
			}
		}
		setDeletingWhatsApp(null);
	};

	return (
		<MainContainer>
			<ConfirmationModal
				title={
					deletingWhatsApp &&
					`${i18n.t("connections.confirmationModal.deleteTitle")} ${deletingWhatsApp.name}?`
				}
				open={confirmModalOpen}
				setOpen={setConfirmModalOpen}
				onConfirm={() => handleDeleteWhatsApp(deletingWhatsApp.id)}
			>
				{i18n.t("connections.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<QrcodeModal
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				whatsAppId={selectedWhatsApp && !whatsAppModalOpen && selectedWhatsApp.id}
				qrCode1={qrCode} // setQrCode burada geçilecek
			/>
			<WhatsAppModal
				open={whatsAppModalOpen}
				onClose={handleCloseWhatsAppModal}
				whatsAppId={selectedWhatsApp && !qrModalOpen && selectedWhatsApp.id}
			/>
			<MainHeader>
				<Title>{i18n.t("connections.title")}</Title>
				<MainHeaderButtonsWrapper>
					<Button variant="contained" color="primary" onClick={openCheckout}>
						{i18n.t("connections.buttons.add")}
					</Button>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center">{i18n.t("connections.table.name")}</TableCell>
							<TableCell align="center">{i18n.t("connections.table.status")}</TableCell>
							{/*<TableCell align="center">
                Battery - Charging
              </TableCell>*/}
							<TableCell align="center">Platform</TableCell>
							<TableCell align="center">{i18n.t("connections.table.lastUpdate")}</TableCell>
							<TableCell align="center">Expire Date</TableCell>
							<TableCell align="center">{i18n.t("connections.table.default")}</TableCell>
							<TableCell align="center">{i18n.t("connections.table.actions")}</TableCell>
							<TableCell align="center">{i18n.t("connections.table.sub")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{false ? (
							<TableRowSkeleton />
						) : (
							<>
								{whatsApps &&
									whatsApps.length > 0 &&
									whatsApps.map((whatsApp, index) => (
										<TableRow key={whatsApp.id}>
											<TableCell align="center">{whatsApp.name}</TableCell>
											<TableCell align="center">
												{whatsApp.status === "qrcode" ? (
													<Button
														size="small"
														variant="contained"
														color="primary"
														onClick={() => handleOpenQrModal(whatsApp)}
													>
														QR CODE
													</Button>
												) : (
													whatsApp.status
												)}
											</TableCell>
											{/*<TableCell align="center">
                        {whatsApp.battery + "% - " + whatsApp.plugged}
                      </TableCell>*/}
											<TableCell align="center">{whatsApp.platform}</TableCell>
											<TableCell align="center">
												{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
											</TableCell>
											<TableCell id={whatsApp.id} align="center">
												{calculateLastDate(whatsApp)}
											</TableCell>
											<TableCell align="center">
												{whatsApp.isDefault && <CheckCircle style={{ color: green[500] }} />}
											</TableCell>
											<TableCell align="center">
												<IconButton size="small" onClick={() => handleEditWhatsApp(whatsApp)}>
													<Edit />
												</IconButton>
												<IconButton size="small" onClick={(e) => handleChangeWhatsapp(whatsApp.id)}>
													<WhatsApp />
												</IconButton>
												{/*<IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingWhatsApp(whatsApp);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>*/}
											</TableCell>
											<TableCell align="center">
												{whatsApp.subUpdateUrl && whatsApp.isActive ? (
													<Button size="small" onClick={() => openCheckout(whatsApp, whatsApp.subUpdateUrl,"update")}>
														Update
													</Button>
												) : (
													<></>
												)}

												{whatsApp.subCancelUrl && whatsApp.isActive ? (
													<Button size="small" onClick={() => openCheckout(whatsApp, whatsApp.subCancelUrl,"cancel")}>
														UnSubscribe
													</Button>
												) : (
													<Button size="small" onClick={() => openCheckout(whatsApp)}>
														Subscribe {whatsApp.isActive}
													</Button>
												)}
											</TableCell>
										</TableRow>
									))}
							</>
						)}
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Connections;
