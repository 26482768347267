import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManager/";
import ProductsManager from "../../components/ProductsManager/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		padding: theme.spacing(4),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
	},

	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
	},
	productsWrapper: {
		display: "flex",
		height: "100%",
		backgroundColor: "#eee",
		padding: "0px",
		flexDirection: "column",
	},
	messagessWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: "#eee",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	},
}));


const Chat = () => {
	const classes = useStyles();
	const { ticketId } = useParams();
	const [productOpen, setProductOpen] = useState(false)

	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					<Grid item xs={3} className={classes.contactsWrapper}>
						<TicketsManager />
					</Grid>
					<Grid item xs={productOpen ? 6: 9} className={classes.messagessWrapper}>
						{ticketId ? (
							<>
								<Ticket setProductOpen={setProductOpen}  productOpen={productOpen} />
							</>
						) : (
							<Paper square variant="outlined" className={classes.welcomeMsg}>
								<span>{i18n.t("chat.noTicketMessage")}</span>
							</Paper>
						)}
					</Grid>
						{productOpen ? (
								<Grid item xs={3} className={classes.productsWrapper}>
									<ProductsManager setProductOpen={setProductOpen} />

								</Grid>

						) : (
							<>
							</>

						)}
				</Grid>
			</div>
		</div>
	);
};

export default Chat;
